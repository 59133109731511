<template>
  <div class="p-1">
    <b-overlay :show="showOverlay">
        <b-form @submit="onSubmit" autocomplete="off">
            <template>
              <b-form-group label-cols-sm="12" label-cols-md="4" label-for="title" v-for="(n, index) in myNames" :key="index">
                <template #label>
                  <b-avatar variant="primary" :src="n.language.icon" size="1.3rem" class="mr-2"></b-avatar>{{n.language.name}}
                </template>
                <b-form-input v-model="n.name" :placeholder="$t('tags.enter-name')"></b-form-input>
              </b-form-group>
            </template>

            <b-form-group label-cols-sm="12" :label="$t('tags.note')" label-cols-md="4" label-for="note">
                  <b-form-input id="note" v-model="tag.note" :placeholder="$t('tags.note')"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('tags.published')" label-for="published">
              <b-form-checkbox id="published" v-model="tag.published" name="check-button" size="lg" switch></b-form-checkbox>
            </b-form-group>

            <b-form-group label-cols-sm="12" label-cols-md="4" v-if="tag.language">
              <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
                <b-button type="submit" variant="primary">{{ $t('pages.edit-page')}}</b-button>
              </b-overlay>
              <template v-if="!page.deleted">
                <b-overlay :show="deleteSpinner" spinner-small spinner-variant="danger" opacity="0.5" class="pl-5 d-inline-block">
                  <b-button type="button" @click="deletePage" variant="outline-danger">{{ $t('pages.delete-page')}}</b-button>
                </b-overlay>
              </template>
              <template v-else>
                <b-overlay :show="deleteSpinner" spinner-small spinner-variant="warning" opacity="0.5" class="pl-5 d-inline-block">
                  <b-button type="button" @click="restorePage" variant="warning">{{ $t('pages.restore-page')}}</b-button>
                </b-overlay>
              </template>
            </b-form-group>
            <b-form-group label-cols-sm="12" label-cols-md="4" v-else>
              <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
                <b-button type="submit" variant="primary">{{ $t('pages.add-page')}}</b-button>
              </b-overlay>
            </b-form-group>
        </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BFormCheckbox} from 'bootstrap-vue'

export default {
  name: 'TagForm',
  components: { BAvatar, BFormCheckbox},
  data: function() {
    return {
      languages: [],
      myNames: [],
      showOverlay: false,
      deleteSpinner: false,
      saveSpinner: false
    }
  },
  props: {
    tag: Object
  },

  created: async function() {
    this.showOverlay = true
    await this.$http.get("/cms/api/languages/enabled/", { headers: {Authorization: this.getAuthData().accessToken }}).then(response => {
        this.languages = response.data;
        for (var i = 0; i < this.languages.length; i++) {
          var l = this.languages[i];
          var name = this.getNameForLanguage(this.tag.names, l);
          this.myNames.push(name);
        }
      }).catch(error => {
        this.handleError(error);
      })
    this.showOverlay = false;
  },

  methods: {
    onSubmit: function() {
      this.saveSpinner = true;
      this.tag.names = this.myNames;
      // console.log("Snimam tag");
      this.$http.post('/cms/api/tag/save/', this.tag, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          this.saveSpinner = false;
          this.$router.push({name : 'Tags'});
        }).catch(e => {
          this.saveSpinner = false;
          var message = this.handleError(e);
        })
    },

    getNameForLanguage: function(names, l) {
      if (names) {
        for (var i = 0; i < names.length; i++) {
          var n = names[i];
          if (n.language.id === l.id) {
            return {id: n.id, name: n.name, language: l}
          }
        }
      }
      return {id: null, name: "", language: l}
    }
  }
}
</script>
<style></style>
