<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('tags.add-tag') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <TagForm :tag="tag" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import TagForm from '@/components/admin/tag/TagForm.vue'

export default {
  name: 'EditTag',
  components: { NavBar, TagForm },
  data: function() {
    return {
      tag: {},
      showOverlay: false
    }
  },
  created: async function() {
    this.showOverlay = true;
    await this.$http.get("/cms/api/tag/get/" + this.$route.params.tagId + "/", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.tag = r.data;
      }).catch(e => { this.handleError(e); })
    this.showOverlay = false;
  }

}
</script>
<style></style>
